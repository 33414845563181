import request from '@/utils/request'

// 查询文章类型管理列表
export function listType(query) {
  return request({
    url: '/work/type/list',
    method: 'get',
    params: query
  })
}

// 查询文章类型管理详细
export function getType(id) {
  return request({
    url: '/work/type/' + id,
    method: 'get'
  })
}

// 新增文章类型管理
export function addType(data) {
  return request({
    url: '/work/type',
    method: 'post',
    data: data
  })
}

// 修改文章类型管理
export function updateType(data) {
  return request({
    url: '/work/type/update',
    method: 'post',
    data: data
  })
}

// 删除文章类型管理
export function delType(id) {
  return request({
    url: '/work/type/remove/' + id,
    method: 'get'
  })
}

// 导出文章类型管理
export function exportType(query) {
  return request({
    url: '/work/type/export',
    method: 'get',
    params: query
  })
}